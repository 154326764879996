import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseAvatar from 'components/BaseAvatar';
import OrganizationPresenter from 'presenters/OrganizationPresenter';

class OrganizationAvatar extends PureComponent {
  static propTypes = {
    organization: PropTypes.shape().isRequired,
  };

  render() {
    const { organization, ...rest } = this.props;
    const initials = OrganizationPresenter.initials(organization);
    const logoUrl = OrganizationPresenter.logoUrl(organization);

    return <BaseAvatar imageUrl={logoUrl} initials={initials} {...rest} />;
  }
}

export default OrganizationAvatar;
